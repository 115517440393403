import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommonService } from '../../../core/services';
import { environment } from '../../../../environments/environment';
import { NotificationService } from '../../../user-managment/notification/notification';

const imgPath = environment.imgPath;

@Component({
  selector: 'app-curated-playlist',
  templateUrl: './curated-playlist.component.html',
  styleUrls: ['./curated-playlist.component.scss']
})
export class CuratedPlaylistComponent implements OnInit {

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  receiveddata = [];
  filtereddata = [];
  p: string;
  trackByDetail: '';
  imgpath: string;
  albumPic: string;
  artistData = [];
  isImageUpload = false;
  playlist_id: number;
  playlist_name: string;
  songSearched = [];
  playlistSongs = [];
  songsearch: string;
  constructor(private router: Router,
    private commonService: CommonService,
    public notify: NotificationService,

  ) {
    this.imgpath = imgPath;

  }

  ngOnInit() {
    this.commonService.postApi(null, 'albums/get_curated_playlist_1').subscribe(result => {
      console.log('::: data', result);
      if (result.success) {
        this.receiveddata = result.data;
        this.filtereddata = result.data;
        // this.notify.success(result.msg);
      }
    });
  }
  editcase(id) {
    console.log('::: all id', id);
    this.router.navigate(['/admin/curated-add/', id]);
  }
  searchList(e) {
    let term = '';
    if (e.target.value.length > 0) {
      term = e.target.value.toLowerCase();
    }
    let anySrch = false;
    if (term !== undefined) {
      if (term.length > 0) {
        this.receiveddata = this.filtereddata.filter(data => {
          return (
            data.playlist_name.toLowerCase().indexOf(term) >= 0
          );
        });
        anySrch = true;
      }
    }
    if (!anySrch) {
      this.receiveddata = this.filtereddata;
    }
  }
  addSongs(index) {
    this.songsearch = '';
    this.songSearched = [];
    this.playlist_id = index.playlist_id;
    this.playlist_name = index.playlist_name;
    this.getPlaylistsongs();
  }
  // getFileName(data) {
  //   if (data.parent !== undefined) {
  //     this.albumPic = data.filename;
  //     console.log('::all pics', this.albumPic);
  //     this.isImageUpload = true;
  //   }
  // }
  addSongstoList(item) {
    const info = {
      song: item.trackid,
      playlist_id: this.playlist_id
    };
    this.commonService.postApi(info, 'albums/addSongtoCurated').subscribe(res => {
    console.log('::: this.log', res);
    this.notify.success(res.data.msg);
    this.getPlaylistsongs();
  });
}
  searchSong(e) {
    const info = {
      song: e.target.value,
    };
    this.commonService.postApi(info, 'albums/searchSong').subscribe(res => {
      console.log('::: this.log', res);
      this.songSearched = res.data;
    });
  }
  getPlaylistsongs() {
    const info = {
      id: this.playlist_id,
    };
    this.commonService.postApi(info, 'albums/getPlaylistTrackbyId').subscribe(res => {
    console.log('::: this.log', res);
    this.playlistSongs = res.data;
  });
}
removeSong(removeId) {
  const info = {
    id: removeId
  };
  this.commonService.postApi(info, 'albums/removeTrackbyId').subscribe(res => {
    // console.log('::: this.log', res);
    this.notify.success('Song Removed Successfull from Playlist');
    this.getPlaylistsongs();
    // this.playlistSongs = res.data;
  });
  }
}

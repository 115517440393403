import { Component, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { ItunesService } from './shared/itunes.service';
import { fromEvent,interval } from 'rxjs';
import { debounceTime} from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit,AfterViewInit {
  @ViewChild('searchBox') searchInput: ElementRef;
  // (keyup)="search(searchBox.value)"

  hideResult:boolean;
  searchResults: Array<any> = [];
  url: string;
  constructor(private ituneService: ItunesService,
    public route: ActivatedRoute,
    private router: Router) {}

  ngOnInit(){
    this.url = this.router.url;
    console.log('::this.ur', this.url);
    
  }

  ngAfterViewInit(){
    let buttonStream$=fromEvent(this.searchInput.nativeElement, 'keyup')
    .pipe(debounceTime(1000))
    .subscribe(()=>{
      this.search(this.searchInput.nativeElement.value);
    });

  }

  onResultClick(){
    this.hideResult=true;
    this.searchInput.nativeElement.value='';
  }

  search(param) {
    this.ituneService.search(param).subscribe(
      data => {
        // console.log(data['results']);
        this.hideResult=false;
        this.searchResults = data['results'];
      },
      err => console.log(err)
    );
  }
}

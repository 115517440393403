import { Component, OnInit, Input } from '@angular/core';
import { PlayerService } from 'src/app/shared/player.service';

@Component({
  selector: 'app-playlist-song-track',
  templateUrl: './playlist-song-track.component.html',
  styleUrls: ['./playlist-song-track.component.scss']
})
export class PlaylistSongTrackComponent implements OnInit {
@Input() img: string;
@Input() songid: string;
  constructor(
    private playerService: PlayerService,

  ) { }

  ngOnInit() {
    console.log('::log img', this.img);
    // this.playerService.playTrack(this.songid);
    console.log('::log song', this.songid);

  }
//  playTrack(track) {
//     // this.isPlaying = true;
//   }
}

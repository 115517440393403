import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, timer } from 'rxjs';
import { AuthenticationService, CommonService } from '../services';
import { Router } from '@angular/router';

@Injectable()

export class JwtInterceptor implements HttpInterceptor {

  time: any;
  offset = 0;
  counter = 0;
  source = timer(1200000);
  connectionStatus: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private router: Router,
  ) { }


  // check the authentication by token
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const currentUser = this.authenticationService.currentUserValue;
    this.authenticationService.checkConnection.subscribe(value => this.connectionStatus = value);
    if (this.connectionStatus === false) {
      location.reload(true);
    } else if (currentUser) {
      request = request.clone({
        setHeaders: {
          'x-auth-token': currentUser
        }
      });
      this.timer();
      // this.activityLog('SL');
      return next.handle(request);
    } else {
      this.timer();
      return next.handle(request);
    }
  }

  // *****sett the timmer*****
  timer() {
    if (this.counter !== 0) {
      this.time.unsubscribe();
    }
    this.time = this.source.subscribe(() => {
      // this.authenticationService.setLogoutFlag();
      // this.authenticationService.clearLocalStorage();
      localStorage.removeItem('currentUser');
      this.router.navigateByUrl('');
    });
    this.counter++;
  }

  // *****.Activity Log*****
  // activityLog(index) {
  //   if (this.commonService.getLocal('route') !== this.router.url) {
  //     if (this.offset === 0) {
  //       this.offset++;
  //       const payload = {
  //         path: this.router.url,
  //         loginstatus: index
  //       };
  //       this.commonService.postApi(payload, 'common/activityLog').subscribe(res => {
  //         if (res.success) {
  //           this.offset = 0;
  //         } else {
  //           this.offset = 0;
  //         }
  //       });
  //     }
  //   }
  //   this.commonService.setLocal('route', this.router.url);
  // }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { IFormMasterSector } from '../../../user-managment/interface';
import { CommonService } from '../../../core/services';
import { NotificationService } from '../../../user-managment/notification/notification';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
const imgPath= environment.imgPath;

@Component({
  selector: 'app-add-curated',
  templateUrl: './add-curated.component.html',
  styleUrls: ['./add-curated.component.scss']
})
export class AddCuratedComponent implements OnInit {
  applicationForm: FormGroup;
  formDetails: IFormMasterSector = {} as IFormMasterSector;
  playlistId: number;
  albumPic: string;
  imgpath: string;
  artistData= [];
  isImageUpload = false;
  constructor(
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    public notify: NotificationService,
    private router: Router,
    public route: ActivatedRoute,
  ) { 
    this.imgpath = imgPath;
  }


  ngOnInit() {
    this.route.params.subscribe(id => {
      this.formMasterValidation();
      this.playlistId = +id.id
      if(this.playlistId != 0){
       
        const info = {
          id: this.playlistId
        }
        this.commonService.postApi(info, 'albums/getCuratedPlaylistbyId').subscribe(result => {
          if(result.success){
            this.applicationForm.controls.playlist.setValue(result.data[0].playlist_name);
            this.applicationForm.controls.enabled.setValue(result.data[0].enabled);
            
            this.albumPic = '/curated/'+result.data[0].id+'.png';
            
          }
        })
      }

    });
  }

  // *****form validation*****
  formMasterValidation() {
    this.applicationForm = this.formBuilder.group({
      playlist: ['', Validators.required],
      enabled: [true, Validators.required]
    });
  }

  // *****easy access to error controls*****
  get errorControl() {
    return this.applicationForm.controls;
  }

  // *****form submit*****
  onFormSubmit() {
    if (this.applicationForm.invalid) {
      return;
    } else {
      // this.setValue();
      const info_to_send = {
        id: this.playlistId,
        playlist: this.errorControl.playlist.value,
        file: this.albumPic,
        enabled: this.errorControl.enabled.value,

      };
      this.commonService.postApi(info_to_send, 'albums/addCuratedPlaylist').subscribe(result => {

        if (result.success) {
          this.notify.success(result.msg);
          this.router.navigate(['/admin/curated-view']);
        }
      });
    }
  }
  getFileName(data) {
    if (data.parent !== undefined) {
      this.albumPic = data.filename;
      console.log('::all pics', this.albumPic);
      this.isImageUpload = true;
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services';
import { NotificationService } from '../../user-managment/notification/notification';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private authenticate: AuthenticationService,
    private notify: NotificationService
  ) { }

  // check the error token expire
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      // auto logout if 401 response returned from api
      console.log('error',err);
      if (err.status === 401 || err.status === 400) {
        this.authenticate.clearLocalStorage();
        location.reload(true);
      } else {
        this.notify.warning('It is server side error contact on CEI ');
      }
      const error = err.error.message || err.statusText;
      return throwError(error);
    }));
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRippleModule } from '@angular/material/core';

import {
  MatInputModule, MatFormFieldModule, MatCardModule, MatSelectModule, MatOptionModule, MatStepperModule,
  MatProgressBarModule, MatNativeDateModule, MatButtonModule, MatSlideToggleModule, MatIconModule,
  MatRadioModule, MatDatepickerModule, MatExpansionModule
} from '@angular/material';

import { AppComponent } from './app.component';
import { ArtistComponent } from './artist/artist.component';
import { AlbumComponent } from './album/album.component';
import { TrackComponent } from './track/track.component';
import { PlayerComponent } from './player/player.component';
import { TrackControlComponent } from './track/track-control/track-control.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { TracksComponent } from './tracks/tracks.component';
import { LoginComponent } from './user-managment/login/login.component';
import { ValidationComponent } from './user-managment/helper';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { JwtInterceptor, ErrorInterceptor } from './core/interceptor';
import { NgxPaginationModule } from 'ngx-pagination';
import { SnotifyModule, SnotifyService, ToastDefaults, SnotifyPosition } from 'ng-snotify';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { ArtistViewComponent } from './masters/artist/artist-view/artist-view.component';
import { ArtistFormComponent } from './masters/artist/artist-form/artist-form.component';
import { FileUploadComponent } from './masters/file-upload/file-upload.component';
import { FileUploadModule } from 'ng2-file-upload';
import { AlbumsViewComponent } from './masters/albums/albums-view/albums-view.component';
import { FanViewComponent } from './masters/fan/fan-view/fan-view.component';
import { TrackViewComponent } from './masters/track/track-view/track-view.component';
import { InnerLayoutComponent } from './layout/inner-layout/inner-layout.component';
import { PlaySongComponent } from './masters/play-song/play-song.component';
import { SignupComponent } from './user-managment/signup/signup.component';
import { CuratedPlaylistComponent } from './masters/curated/curated-playlist/curated-playlist.component';
import { AddCuratedComponent } from './masters/curated/add-curated/add-curated.component';
import { PlaylistTrackComponent } from './masters/playlist/playlist-track/playlist-track.component';
import { PlaylistSongTrackComponent } from './masters/playlist/playlist-song-track/playlist-song-track.component';
import { AlbumFormComponent } from './masters/albums/album-form/album-form.component';
import { FanFormComponent } from './masters/fan/fan-form/fan-form.component';
import { TrackFormComponent } from './masters/track/track-form/track-form.component';
import { EventsComponent } from './masters/events/events.component';
import { DashboardComponent } from './masters/dashboard/dashboard.component';

// import { NgxAudioPlayerModule } from 'ngx-audio-player';

// import { ModalModule } from 'angular-custom-modal';
import { StreamReportComponent } from './reports/stream-report/stream-report.component';
import { MainComponent } from './static/main/main.component';
import { UserDetailsComponent } from './masters/user-details/user-details.component';
import { TermsComponent } from './layout/terms/terms.component';
import { AboutUsComponent } from './layout/about-us/about-us.component';
import { PrivacyComponent } from './layout/privacy/privacy.component';
import { FaqComponent } from './layout/faq/faq.component';
@NgModule({
  declarations: [
    AppComponent,
    ArtistComponent,
    AlbumComponent,
    TrackComponent,
    PlayerComponent,
    TrackControlComponent,
    HomeComponent,
    TracksComponent,
    LoginComponent,
    ValidationComponent,
    HeaderComponent,
    FooterComponent,
    ArtistViewComponent,
    ArtistFormComponent,
    FileUploadComponent,
    AlbumsViewComponent,
    FanViewComponent,
    TrackViewComponent,
    InnerLayoutComponent,
    PlaySongComponent,
    SignupComponent,
    CuratedPlaylistComponent,
    AddCuratedComponent,
    PlaylistTrackComponent,
    PlaylistSongTrackComponent,
    AlbumFormComponent,
    FanFormComponent,
    TrackFormComponent,
    EventsComponent,
    DashboardComponent,
    StreamReportComponent,
    MainComponent,
    UserDetailsComponent,
    TermsComponent,
    AboutUsComponent,
    PrivacyComponent,
    FaqComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatListModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    AppRoutingModule,
    MatCardModule,
    MatRippleModule,
    NgxPaginationModule,
    SnotifyModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatOptionModule,
    MatStepperModule,
    MatProgressBarModule,
    MatNativeDateModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatIconModule,
    MatRadioModule,
    MatDatepickerModule,
    MatExpansionModule,
    FileUploadModule,
    // NgxAudioPlayerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: 'SnotifyToastConfig',
      useValue: ToastDefaults
    },
    SnotifyService,
    SnotifyPosition
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

import { Component, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { ItunesService } from '../../shared/itunes.service';
import { fromEvent, interval } from 'rxjs';
import { debounceTime} from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { CommonService } from '../../core/services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-inner-layout',
  templateUrl: './inner-layout.component.html',
  styleUrls: ['./inner-layout.component.scss']
})
export class InnerLayoutComponent implements OnInit , AfterViewInit {
  @ViewChild('searchBox') searchInput: ElementRef;
  // (keyup)="search(searchBox.value)"

  hideResult: boolean;
  searchResults: Array<any> = [];
  role: string;
  id: string;
  constructor(private ituneService: ItunesService,
    private commonService: CommonService,
    public route: ActivatedRoute,
    private router: Router) {}

  ngOnInit() {
    this.role = this.commonService.getLocal('role');
    this.id = this.commonService.getLocal('id');
  }

  ngAfterViewInit() {
    let buttonStream$ = fromEvent(this.searchInput.nativeElement, 'keyup')
    .pipe(debounceTime(1000))
    .subscribe(() => {
      this.search(this.searchInput.nativeElement.value);
    });

  }

  onResultClick() {
    this.hideResult = true;
    this.searchInput.nativeElement.value = '';
  }

  search(param) {
    this.ituneService.search(param).subscribe(
      data => {
        // console.log(data['results']);
        this.hideResult = false;
        this.searchResults = data['results'];
      },
      err => console.log(err)
    );
  }
  onClickProfile () {
    this.router.navigate(['/user-detail/' + this.id]) ;
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { IFormMasterSector } from '../../../user-managment/interface';
import { CommonService } from '../../../core/services';
import { NotificationService } from '../../../user-managment/notification/notification';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
const imgPath = environment.imgPath;
declare var $: any;

@Component({
  selector: 'app-album-form',
  templateUrl: './album-form.component.html',
  styleUrls: ['./album-form.component.scss']
})
export class AlbumFormComponent implements OnInit {
  @Input() type: number;

  applicationForm: FormGroup;
  formDetails: IFormMasterSector = {} as IFormMasterSector;
  albumId: string;
  albumPic: string;
  imgpath: string;
  artistData = [];
  isFileUpload = false;

  isAdd = false;
  constructor(
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    public notify: NotificationService,
    private router: Router,
    public route: ActivatedRoute,
  ) { }


  ngOnInit() {
    console.log('::: idd', this.type);
    this.getArtist();
    this.formMasterValidation();
    if (this.type === undefined) {
      this.isAdd = false;
      this.route.params.subscribe(id => {
        this.imgpath = imgPath;
        if (id.id == '0') {
          this.albumId = id.id;
        } else {
          this.albumId = id.id;
          const info = {
            id: id.id
          };

          this.commonService.postApi(info, 'albums/getAlbumsbyId').subscribe(result => {
            if (result.success) {
              this.applicationForm.controls.album.setValue(result.data[0].album_name);
              this.applicationForm.controls.artist.setValue(result.data[0].artist_id);
              this.applicationForm.controls.enabled.setValue(result.data[0].enabled);
              this.albumPic = result.data[0].album_pic;
              // this.receiveddata = result.data;
              // this.notify.success(result.msg);
            }
          });
        }
      });
    } else {
      this.isAdd = true;
      this.albumId = '0';
      this.applicationForm.controls.artist.setValue(this.type);
    }
  }

  // *****form validation*****
  formMasterValidation() {
    this.applicationForm = this.formBuilder.group({
      album: ['', Validators.required],
      artist: ['', Validators.required],
      enabled: [true, Validators.required]
    });
  }

  // *****easy access to error controls*****
  get errorControl() {
    return this.applicationForm.controls;
  }


  // *****form submit*****
  onFormSubmit() {
    if (this.applicationForm.invalid) {
      return;
    } else {
      // this.setValue();
      const info_to_send = {
        id: this.albumId,
        albumName: this.errorControl.album.value,
        file: this.albumPic,
        enabled: this.errorControl.enabled.value,
        artist: this.errorControl.artist.value,
        isfileuploaded: this.isFileUpload
      };
      this.commonService.postApi(info_to_send, 'albums/updateAlbums').subscribe(result => {
        if (result.success) {
          this.notify.success(result.msg);
          if (this.isAdd == false) {
            this.router.navigate(['/admin/album-view']);
          } else {
            this.router.navigate(['user-detail/' + this.type]);
            $('.addAlbumn').modal('hide');
          }
        }
      });
    }
  }
  getFileName(data) {
    if (data.parent !== undefined) {
      this.albumPic = data.filename;
      console.log('::all pics', this.albumPic);
      this.isFileUpload = true;
    }
  }
  getArtist() {
    this.commonService.postApi('', 'albums/getAllArtist').subscribe(result => {
      if (result.success) {
        this.artistData = result.data;
      }
    });
  }
}

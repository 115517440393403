import { Component, Input } from '@angular/core';
import { FormControl, AbstractControl } from '@angular/forms';
import { CommonService } from '../../core/services/index';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'custom-mat-error',
  template: `<mat-error *ngIf="errorMessage !== null">{{errorMessage}}</mat-error>`
})
export class ValidationComponent {

  @Input() control: AbstractControl;

  constructor(
  ) { }

  get errorMessage() {
    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName)) {
        return CommonService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
      }
    }
    return null;
  }

}

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommonService } from '../../../core/services';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-track-view',
  templateUrl: './track-view.component.html',
  styleUrls: ['./track-view.component.scss']
})
export class TrackViewComponent implements OnInit {

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  receiveddata = [];
  filtereddata = [];
  p: string;
  trackByDetail = '';
  constructor(private router: Router,
    private commonService: CommonService,
) { }

  ngOnInit() {
    this.commonService.postApi(null, 'albums/getAllTrack').subscribe(result => {
      if (result.success) {
        console.log('::: data', result);
        this.receiveddata = result.data;
        this.filtereddata = result.data;
      }
    });
  }
  editcase(id) {
   
    this.router.navigate(['/admin/track-form/', id]);
  }
  searchList(e) {
    let term = '';
    if (e.target.value.length > 0) {
      term = e.target.value.toLowerCase();
    }
    let anySrch = false;
    if (term !== undefined) {
      if (term.length > 0) {
        this.receiveddata = this.filtereddata.filter(data => {
          return (
            data.first_name.toLowerCase().indexOf(term) >= 0 ||  data.last_name.toLowerCase().indexOf(term) >= 0 || data.album_name.toLowerCase().indexOf(term) >= 0 || data.track_name.toLowerCase().indexOf(term) >= 0
          );
        });
        anySrch = true;
      }
    }
    if (!anySrch) {
      this.receiveddata = this.filtereddata;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { ILogin } from '../interface';
import {
  StringPattern,
  PasswordPattern,
  AddressPattern
} from '../helper/index';
import { AuthenticationService, CommonService } from '../../core/services';
import { NotificationService } from '../notification/notification';
import { CryptoService } from '../../core/crypto/crypto.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
// import { SignupComponent } from '../signup/signup.component';
// import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginDetails: ILogin = {} as ILogin;
  recaptchaText = '';
  captchaSVG: any;
  captchaText: any;
  cUrl: string;
  roles: string;

  constructor(
    private authentication: AuthenticationService,
    private formBuilder: FormBuilder,
    public notify: NotificationService,
    private commonService: CommonService,
    private crypto: CryptoService,
    public router: Router,
    public route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    // private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.loginValidation();
    this.captcha();
    this.cUrl = this.router.url;
    console.log('URL', this.cUrl, '::::::' , this.router);
    if (this.cUrl === '/login') {
      this.errorControl.role.setValidators(Validators.required);
      this.errorControl.role.updateValueAndValidity();
      this.roles = 'FAN';
    } else {
      this.errorControl.role.setValidators(null);
      this.errorControl.role.updateValueAndValidity();
      this.roles = 'ADMN';
    }
  }

  // *****captcha*****
  captcha() {
    this.commonService.postApi({}, 'Users/captcha').subscribe(res => {
      if (res.success) {
        this.captchaSVG = this.sanitizer.bypassSecurityTrustHtml(res.data.data);
        this.captchaText = res.data.text;
      }
    });
  }

  // form validations
  loginValidation() {
    this.loginForm = this.formBuilder.group({
      userName: this.commonService.validation('', 0, 20),
      password: this.commonService.validation('', 0, 50),
      captcha: this.commonService.validation('', 1, 50),
      role: [false]
    });
  }

  // *****esat to access the form controls*****
  get errorControl() {
    return this.loginForm.controls;
  }

  // login function
  onLoginSubmit() {
    if (this.loginForm.invalid) {
      this.notify.warning('Please Check yours fields');
      return;
    } else {
      // this.spinnerService.show();
      // setTimeout(() => {
      this.loginDetails.mtoken = this.crypto.set('', Math.random());
      this.loginDetails.emailId = this.loginForm.value.userName;
      this.loginDetails.role = this.roles;
      this.loginDetails.password = this.loginForm.value.password;
      // this.loginDetails.password = this.crypto.set(
      //   '',
      //   this.loginForm.value.password
      // );
      // this.loginDetails.copy = this.crypto.set(
      //   '',
      //   this.loginForm.value.captcha
      // );
      // this.loginDetails.fresh = this.crypto.set('', this.captchaText);
      console.log(this.loginDetails, '*******this.loginDetails');
      localStorage.setItem('random', this.loginDetails.random);
      this.authentication.login(this.loginDetails).subscribe(res => {
        if (res.success === 1) {
          console.log('::dattaaa', res);
          this.commonService.setLocal('id', res.id);
          this.commonService.setLocal('role', res.role);
          this.commonService.setLocal('token', res.token);
          const user = res.token;
          localStorage.setItem('currentUser', JSON.stringify(user));
            this.notify.success('User Successfully Loged In');
            // this.spinnerService.hide();
            if (res.role === 'ADMN') {
              this.router.navigate(['/admin/artist-view']);
            } else {
              this.router.navigate(['/admin/main']);
            }
            // } else {
              //   // this.spinnerService.hide();
              //   // location.reload();
              //   // this.router.navigate(['/admin/artist-view']);
              //   // this.notify.warning('Invalid Request');
              // }
            } else {
              // this.spinnerService.hide();
              this.notify.warning('Invalid Email or Password');
          // this.router.navigate(['/admin/ artist-view']);
        }
      });
      // }, 3000);
    }
  }

  onChangeRole(item) {
    if (item.checked === true) {
      this.roles = 'ART';
    } else {
      this.roles = 'FAN';
    }
    console.log(':::: item', this.roles );
  }
  // ****show forget modal *******
  // forgetPasswordModal() {
  //   this.bsModalRef.hide();
  //   setTimeout(() => {
  //     this.bsModalRef = this.modalService.show(ForgotPasswordComponent, {
  //       class: 'login'
  //     });
  //     this.bsModalRef.content.closeBtnName = 'Close';
  //   }, 1000);
  // }

  // show signup modal
  // signUpModal() {
  //   this.bsModalRef.hide();
  //   setTimeout(() => {
  //     this.bsModalRef = this.modalService.show(SignupComponent, {
  //       class: 'login'
  //     });
  //     this.bsModalRef.content.closeBtnName = 'Close';
  //   }, 1000);
  // }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AddressPattern, StringPattern, NumberPattern, Email, PasswordPattern } from '../helper';
import { AuthenticationService, CommonService } from '../../core/services';
import { ISignup } from '../interface';
import { NotificationService } from '../notification/notification';
declare var $: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  signUpForm: FormGroup;
  signUpDetails: ISignup = {} as ISignup;
  genderType: string[] = ['Mr.', 'Ms.', 'Dr.'];
  role = [
    {key: 'Artist', value: 'ART'},
    {key: 'Fan', value: 'FAN'},

  ];
  userType: string[] = ['Supplier', 'Non-Supplier (Consumer)'];
  countryType: string[] = ['India', 'USA'];
  selectedValue: string;
  isSubmitted = false;
  checked = true;


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private commonService: CommonService,
    private authentication: AuthenticationService,
    public notify: NotificationService,

  ) { }

  ngOnInit() {
    this.signUpValidation();
  }
  signUpValidation() {
    this.signUpForm = this.formBuilder.group({
      // userName:  this.commonService.validation(AddressPattern , 0 , 20),
      firstName: this.commonService.validation(StringPattern , 0 , 20),
      lastName: this.commonService.validation(StringPattern , 0 , 20),
      email:   this.commonService.validation(Email, 0, 50),
      password: this.commonService.validation(PasswordPattern, 0, 50),
      role: [false, Validators.required]
    });
  }
  get errorControl() {
    return this.signUpForm.controls;
  }

    // submit function on signUp
    onSignUpSubmit() {
      debugger
      this.isSubmitted = true;
      if (this.signUpForm.invalid) {
        return;
      } else {
        this.setValues();
        this.authentication.signUp(this.signUpDetails)
          .subscribe(res => {
            if (res.success === 1) {
              this.signUpForm.reset();
            // $('.signuppopup').modal('hide');
              this.notify.success('Please Check your Email');
              // this.router.navigate(['/login']);
              // $('.signuppopup').modal('hide');
            } else {
              console.log(res);
              this.notify.warning(res.data.status);
            }
          });
      }
    }
  // easy  to access the form value
  get fetchValue() {
    return this.signUpForm.value;
  }
      // set the values
  setValues() {
    this.signUpDetails.firstName = this.fetchValue.firstName;
    this.signUpDetails.lastName = this.fetchValue.lastName;
    this.signUpDetails.emailId = this.fetchValue.email.toLowerCase();
    this.signUpDetails.password = this.fetchValue.password;
    this.signUpDetails.role = this.fetchValue.role  == false ? 'FAN' : 'ART' ;
    this.signUpDetails.referredBy = 'Admin';
    this.signUpDetails.mtoken = 'mtoken';
    this.signUpDetails.mtype = 'Web';
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommonService } from '../../../core/services';
import { ActivatedRoute } from '@angular/router';

import { PlayerService } from 'src/app/shared/player.service';
import { environment } from '../../../../environments/environment';
const imgPath = environment.imgPath;

declare var $: any;

@Component({
  selector: 'app-playlist-track',
  templateUrl: './playlist-track.component.html',
  styleUrls: ['./playlist-track.component.scss']
})
export class PlaylistTrackComponent implements OnInit {


  receiveddata = [];
  imgpath: string;
  img: string;
  songPath: string;
  isData = false;
  playlistId: number;
  trackId: number;
  songList = [];
  track_id: number;
  streamid: number;

  songIndex: number;
  @ViewChild('player')
  playerRef;
  player: any;

  // msaapDisplayTitle = true;
  // msaapDisplayPlayList = true;
  // msaapPageSizeOptions = [2,4,6];
  // msaapDisplayVolumeControls = true;
  // msaapPlaylist: Track[]  = [];
  // showAudioPlayer = false;
  constructor(private router: Router,
    private commonService: CommonService,
    private playerService: PlayerService,
    public route: ActivatedRoute,

  ) {
    this.imgpath = imgPath;
    
  }
  ngOnInit() {
    this.player = this.playerRef.nativeElement;
    this.route.params.subscribe(id => {
      this.playlistId = +id.id;

      const info = {
        playlistId: +id.id
      };
      this.commonService.postApi(info, 'albums/get_playlist_tracks_1').subscribe(result => {
        if (result.success) {
          this.receiveddata = result.data;
          this.songPath = imgPath + this.receiveddata[0].track_file;
          this.img = this.imgpath + this.receiveddata[0].album_pic;
          this.songIndex = 0;
          this.trackId = this.receiveddata[0].track_id;
          this.isData = true;
          // this.track_id = this.receiveddata[0].track_id;
          // this.receiveddata.forEach(element => {
          //     this.msaapPlaylist.push({'link': imgPath+element.track_file, 'title': element.track_name});
          // });

          // console.log('::this.song list', this.msaapPlaylist);
          // this.showAudioPlayer = true;
          setTimeout(() => {
            this.playTrack(this.songPath, this.trackId);
          }, 2000);
        }
      });
    });
  }
  onClickSong(song, imgs, id, index) {
    this.isData = false;
    this.songPath = imgPath + song;
    this.img = imgPath + imgs;
    this.playTrack(imgPath + song, id);
    this.isData = true;
    this.trackId = id;
    this.songIndex = index;
  }
  playTrack(track, trackid?) {
    const data = {
      trackId: trackid
    };
    this.commonService.postApi(data, 'albums/start_track_1').subscribe(result => {
      if (result.success == 1) {
        this.player.src = track;
        this.player.play();
        this.streamid = result.data;
      // this.playerService.playTrack(track);
    // let call = this;
    // setTimeout(function () {
    //   $('.signuppopup').modal('show');
    //   call.onClickSongforPause(trackid);
    //  }, 30000);
      }
    });
    // this.isPlaying = true;
  }
  clicktoPlay() {
    this.playTrack(this.songPath);
  }
  onClickSongforPause(id) {
    const info = {
      streamId: this.streamid
    }
    this.commonService.postApi(info, 'albums/stop_track_1').subscribe(result => {
      if (result.success == 1) {
        // this.playerService.pauseTrack();
        this.player.pause();

      }
    });
    this.trackId = id;

  }

  openNav() {
    document.getElementById("mySidenav").style.width = "250px";
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  playerEnded() {
    this.onClickSongforPause(this.streamid);
    if ((this.songIndex + 1) < this.receiveddata.length) {
      const nextIndex = this.songIndex + 1;
      const nextTrackid = this.receiveddata[nextIndex].track_id;
      const nextSong = this.receiveddata[nextIndex].track_file;
      const nextPic = this.receiveddata[nextIndex].album_pic;
      setTimeout(() => {
        this.onClickSong(nextSong, nextPic, nextTrackid , this.songIndex);
      }, 1000);
      }
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CommonService } from '../../core/services';
import { NotificationService } from '../../user-managment/notification/notification';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { ExportToCsv } from 'export-to-csv';
 
@Component({
  selector: 'app-stream-report',
  templateUrl: './stream-report.component.html',
  styleUrls: ['./stream-report.component.scss']
})
export class StreamReportComponent implements OnInit {
  applicationForm: FormGroup;
  countrys = [
    { value: 'All', viewValue: 'ALL' },
    { value: 'US', viewValue: 'US' },
    { value: 'UK', viewValue: 'UK' },
    { value: 'CA', viewValue: 'Canada' }
  ];
  artist = [];
  albums = [];
  song = [];
  streamData = [];
  minClaimDate: Date;
  maxClaimDate = new Date();
  minToClaimDate: Date;

  p: string;

  allArtist: Observable<string[]>;
  allAlbums: Observable<string[]>;
  allSong: Observable<string[]>;

  constructor(
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    public notify: NotificationService,
    private router: Router,
    public route: ActivatedRoute,
    ) { }
    
    ngOnInit() {
      this.formMasterValidation();
      this.commonService.postApi(null, 'albums/getAllArtist').subscribe(result => {
        if (result.success) {
          this.artist = result.data;
        }
      });
      this.commonService.postApi(null, 'albums/getAllAlbums').subscribe(result => {
        if (result.success) {
          this.albums = result.data;
        }
      });
      this.commonService.postApi(null, 'albums/getAllTrack').subscribe(result => {
        if (result.success) {
          this.song = result.data;
        }
      });
    this.allArtist = this.errorControl.artist.valueChanges.pipe(startWith(''),
        map(value => this._filterArtist(value)));
    this.allAlbums = this.errorControl.albums.valueChanges.pipe(startWith(''),
        map(value => this._filterAlbums(value)));
    this.allSong = this.errorControl.song.valueChanges.pipe(startWith(''),
        map(value => this._filterSong(value)));
  }
  // *****form validation*****
  formMasterValidation() {
    this.applicationForm = this.formBuilder.group({
      country: [''],
      artist: [''],
      albums: [''],
      song: [''],
      periodFrom: ['', Validators.required],
      periodTo: ['', Validators.required]
    });
  }

  // *****easy access to error controls*****
  get errorControl() {
    return this.applicationForm.controls;
  }

  private _filterArtist(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.artist.filter(option => option.first_name.toLowerCase().includes(filterValue));
  }
  private _filterAlbums(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.albums.filter(option => option.album_name.toLowerCase().includes(filterValue));
  }
  private _filterSong(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.song.filter(option => option.track_name.toLowerCase().includes(filterValue));
  }

  onFormSubmit() {
    const info_to_send = {
      country: this.errorControl.country.value == '' ? 'All' : this.errorControl.country.value,
      artist: this.errorControl.artist.value,
      albums: this.errorControl.albums.value,
      song: this.errorControl.song.value,
      from: this.errorControl.periodFrom.value,
      to: this.errorControl.periodTo.value,
    }

    this.commonService.postApi(info_to_send, 'albums/stream_data').subscribe(result => {
      if (result.success) {
        this.streamData = result.data;
      }
    });
  }

  onExport() {
    const options = { 
      fieldSeparator: ',',
      filename: 'whoozl',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      title: '',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: ['Country', 'Track','Albumn', 'Artist', 'Count'] //<-- Won't work with useKeysAsHeaders present!
    };
   
  const csvExporter = new ExportToCsv(options);
   
  csvExporter.generateCsv(this.streamData);
  }

  onChangeFromDate() {
    this.minToClaimDate = this.errorControl.periodFrom.value;
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommonService } from '../../core/services';
import { ActivatedRoute } from '@angular/router';

import { PlayerService } from 'src/app/shared/player.service';
import { environment } from '../../../environments/environment';
const imgPath= environment.imgPath;
declare var $: any;
@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  receiveddata = [];
  imgpath: string;
  img: string;
  address: string;
  newUrl: string;
  eventName: string;
  venue: string;
  remarks: string;
  date: string;
  constructor(private router: Router,
    private commonService: CommonService,
    private playerService: PlayerService,
    public route: ActivatedRoute,

) { 
  this.imgpath = imgPath;
}
  ngOnInit() {
    this.route.params.subscribe(id => {
      const info = {
        id: id.id
      };
      this.commonService.postApi(info, 'events/getEventByID').subscribe(result => {
        if (result.success) {
          this.receiveddata = result.data;
          console.log('::: data', this.receiveddata);
          this.address = this.receiveddata[0].location;
          this.img = this.receiveddata[0].profile_pic
          this.eventName = this.receiveddata[0].title
          this.venue = this.receiveddata[0].location
          this.date = this.receiveddata[0].duration
          this.remarks = this.receiveddata[0].remarks
          // console.log(':: this.img', this.img);
          
          // this.notify.success(result.msg);
          // this.clicktoPlay();
          // var q=encodeURIComponent(this.address.text());
          // encodeURIComponent(this.address);
          // console.log('::log', );
          this.newUrl = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyAWFgDmV07T9VeqBN-RrusYR95PtlHwCCE&q='+encodeURIComponent(this.address);
          var el =document.getElementById("map") 
          el.setAttribute('src', this.newUrl);
        }
      });
    });
  }
}

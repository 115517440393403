import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AlbumComponent } from './album/album.component';
import { TracksComponent } from './tracks/tracks.component';
import { LoginComponent } from './user-managment/login/login.component';
import { ArtistViewComponent } from './masters/artist/artist-view/artist-view.component';
import { ArtistFormComponent } from './masters/artist/artist-form/artist-form.component';
import { AlbumsViewComponent } from './masters/albums/albums-view/albums-view.component';
import { FanViewComponent } from './masters/fan/fan-view/fan-view.component';
import { TrackViewComponent } from './masters/track/track-view/track-view.component';
import { InnerLayoutComponent } from './layout/inner-layout/inner-layout.component';
import { PlaySongComponent } from './masters/play-song/play-song.component';
import { CuratedPlaylistComponent } from './masters/curated/curated-playlist/curated-playlist.component';
import { AddCuratedComponent } from './masters/curated/add-curated/add-curated.component';
import { PlaylistTrackComponent } from './masters/playlist/playlist-track/playlist-track.component';
import { AlbumFormComponent } from './masters/albums/album-form/album-form.component';
import { FanFormComponent } from './masters/fan/fan-form/fan-form.component';
import { TrackFormComponent } from './masters/track/track-form/track-form.component';
import { EventsComponent } from './masters/events/events.component';
import { DashboardComponent } from './masters/dashboard/dashboard.component';
import { SignupComponent } from './user-managment/signup/signup.component';
import { StreamReportComponent } from './reports/stream-report/stream-report.component';
import { MainComponent } from './static/main/main.component';
import { UserDetailsComponent } from './masters/user-details/user-details.component';

import { TermsComponent } from './layout/terms/terms.component';
import { AboutUsComponent } from './layout/about-us/about-us.component';
import { PrivacyComponent } from './layout/privacy/privacy.component';
import { FaqComponent } from './layout/faq/faq.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'music', component: HomeComponent },
  // { path: ':id/:name', component: AlbumComponent },
  { path: ':id/:name/:colllection_id/:collection_name', component: TracksComponent },
  { path: 'login', component: LoginComponent },
  { path: 'adminlogin', component: LoginComponent },
  { path: 'signup', component: SignupComponent },

  { path: 'admin', component: InnerLayoutComponent, children: [
    { path: 'artist-view', component: ArtistViewComponent },
    { path: 'album-view', component: AlbumsViewComponent },
    { path: 'fan-view', component: FanViewComponent },
    { path: 'track-view', component: TrackViewComponent },
    { path: 'curated-view', component: CuratedPlaylistComponent },
    { path: 'curated-add/:id', component: AddCuratedComponent },
    { path: 'artist-form/:id', component: ArtistFormComponent },
    { path: 'album-form/:id', component: AlbumFormComponent },
    { path: 'fan-form/:id', component: FanFormComponent },
    { path: 'track-form/:id', component: TrackFormComponent },
    { path: 'dashboard', component: DashboardComponent },
    { path: 'stream', component: StreamReportComponent },
    { path: 'main', component: MainComponent },
  ]},
  { path: 'layout', component: InnerLayoutComponent, children: [
    { path: 'terms', component: TermsComponent },
    { path: 'about', component: AboutUsComponent },
    { path: 'privacy', component: PrivacyComponent },
    { path: 'faq', component: FaqComponent },
  ]},
  { path: 'playlist-song/:id', component: PlaylistTrackComponent },
  { path: 'user-detail/:id', component: UserDetailsComponent },
  { path: 'play-song/:id', component: PlaySongComponent },
  { path: 'events/:id', component: EventsComponent },
  { path: '***', redirectTo: '/music', pathMatch: 'full' },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}

import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { IFormMasterSector } from '../../../user-managment/interface';
import { CommonService } from '../../../core/services';
import { NotificationService } from '../../../user-managment/notification/notification';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
const imgPath= environment.imgPath;

@Component({
  selector: 'app-track-form',
  templateUrl: './track-form.component.html',
  styleUrls: ['./track-form.component.scss']
})
export class TrackFormComponent implements OnInit {
  applicationForm: FormGroup;
  formDetails: IFormMasterSector = {} as IFormMasterSector;
  trackId: string;
  imgpath: string;
  artistData= [];
  isFileUpload = false;
  constructor(
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    public notify: NotificationService,
    private router: Router,
    public route: ActivatedRoute,
  ) { }


  ngOnInit() {
    this.route.params.subscribe(id => {
  
      this.imgpath = imgPath;
      if (id.id == '0') {
        this.trackId = id.id;
      } else {
        this.trackId = id.id;
        // this.sectorId = id.id;
        const info = {
          id: id.id
        };
        
        this.commonService.postApi(info, 'albums/getAllTrackbyId').subscribe(result => {
          if (result.success) {
            this.applicationForm.controls.track.setValue(result.data[0].track_name);
            this.applicationForm.controls.album.setValue(result.data[0].album_name);
            this.applicationForm.controls.artist.setValue(result.data[0].first_name + result.data[0].last_name);
            this.applicationForm.controls.genre.setValue(result.data[0].genre_name);
            this.applicationForm.controls.enabled.setValue(result.data[0].enabled);
            this.applicationForm.controls.top.setValue(result.data[0].top_pick);
            this.applicationForm.controls.newRelease.setValue(result.data[0].is_new_relase);
          }
        });
      }
    });

    this.formMasterValidation();
  }

  // *****form validation*****
  formMasterValidation() {
    this.applicationForm = this.formBuilder.group({
      track: ['', Validators.required],
      album: ['', Validators.required],
      artist: ['', Validators.required],
      genre: ['', Validators.required],
      enabled: [true, Validators.required],
      top: [false, Validators.required],
      newRelease: [false]
    });
  }

  // *****easy access to error controls*****
  get errorControl() {
    return this.applicationForm.controls;
  }

  // *****form submit*****
  onFormSubmit() {
    if (this.applicationForm.invalid) {
      return;
    } else {
      // this.setValue();
      const info_to_send = {
        id: this.trackId,
        track: this.errorControl.track.value,
        enabled: this.errorControl.enabled.value,
        top: this.errorControl.top.value,
        newRelease: this.errorControl.newRelease.value
      }
      
      this.commonService.postApi(info_to_send, 'albums/updateTracks').subscribe(result => {
        
        if (result.success) {
          this.notify.success(result.msg);
          this.router.navigate(['/admin/track-view']);
        }
      });
    }
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommonService } from '../../core/services';
import { ActivatedRoute } from '@angular/router';

import { PlayerService } from 'src/app/shared/player.service';
import { environment } from '../../../environments/environment';
declare var $: any;

import { FormGroup, Validators, FormBuilder } from '@angular/forms';

const imgPath = environment.imgPath;
@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  receiveddata = [];
  imgpath: string;
  img: string;
  songPath: string;
  isData = false;
  playlistId: number;
  trackId = 0;
  songList = [];
  track_id: number;
  streamid: number;

  songIndex: number;
  @ViewChild('player')
  playerRef;
  player: any;


  // Profile
  profileId: number;
  coverPic: string;
  profilePic: string;
  profileName: string;
  allAlbumns = [];
  albumnName: string;
  albumnid: string;

  applicationForm: FormGroup;
  genreArray = [];
  songpath: string;
  isFileUpload = false;
  follower_count: string;
  following_count: string;
  instagra_link: string;
  facebook_link: string;
  twitter_link: string;
  website: string;
  profile_intro: string;

  loggedId: any;
  isLoggedInSame = false;
  constructor(private router: Router,
    private commonService: CommonService,
    private playerService: PlayerService,
    public route: ActivatedRoute,
    private formBuilder: FormBuilder,


  ) {
    this.imgpath = imgPath;
  }
  ngOnInit() {
    this.formMasterValidation();
    this.loggedId = this.commonService.getLocal('id');
    console.log('::: log', this.loggedId);
    this.player = this.playerRef.nativeElement;
    this.route.params.subscribe(id => {
      this.profileId = +id.id;

      if (this.loggedId == this.profileId) {
        this.isLoggedInSame = true;
      } else {
        this.isLoggedInSame = false;
      }
      const info = {
        artistId: +id.id
      };
      this.commonService.postApi(info, 'users/getProfileById').subscribe(result => {
        if (result.success) {
          this.coverPic = this.imgpath + result.data[0].cover_pic;
          this.profilePic = this.imgpath + result.data[0].profile_pic;
          this.songPath = '1';
          this.profileName = result.data[0].first_name + ' ' + result.data[0].last_name;
          this.isData = true;
        }
      });
      const param = {
        id: +id.id
      };
      this.commonService.postApi(param, 'albums/getAlbumsbyArtistId').subscribe(res => {
        if (res.success) {
          this.allAlbumns = res.data;
        }
      });
      const profileparam = {
        id: this.profileId,
        artistId: 0
      };
      this.commonService.postApi(profileparam, 'users/get_profile_1').subscribe(resp => {
        this.follower_count = resp.data[0].follower_count;
        this.following_count = resp.data[0].following_count;
        this.instagra_link = resp.data[0].instagra_link;
        this.facebook_link = resp.data[0].facebook_link;
        this.twitter_link = resp.data[0].twitter_link;
        this.website = resp.data[0].website;
        this.profile_intro = resp.data[0].profile_intro;
      });
    });
    const genre = {
      genreId: 0,
      showAll: true
    };
    this.commonService.postApi(genre, 'master/get_genre_1').subscribe(result => {
      if (result.success) {
        this.genreArray = result.data;
      }
    });
  }
  onClickSong(song, id, index) {
    this.isData = false;
    this.songPath = imgPath + song;
    // this.img = imgPath + imgs;
    this.playTrack(imgPath + song, id);
    this.isData = true;
    this.trackId = id;
    this.songIndex = index;
  }
  playTrack(track, trackid?) {
    console.log('::log', track);
    this.player.src = track;
    this.player.play();
    // this.playerService.playTrack(track);
    const data = {
      trackId: trackid
    };
    // this.commonService.postApi(data, 'albums/start_track_1').subscribe(result => {
    //   if (result.success == 1) {
    //     this.player.src = track;
    //     this.player.play();
    //     this.streamid = result.data;
    //   this.playerService.playTrack(track);
    // // let call = this;
    // // setTimeout(function () {
    // //   $('.signuppopup').modal('show');
    // //   call.onClickSongforPause(trackid);
    // //  }, 30000);
    //   }
    // });
    // this.isPlaying = true;
  }
  clicktoPlay() {
    this.playTrack(this.songPath);
  }
  onClickSongforPause(id) {
    this.player.pause();

    const info = {
      streamId: this.streamid
    };
    this.commonService.postApi(info, 'albums/stop_track_1').subscribe(result => {
      if (result.success == 1) {
        // this.playerService.pauseTrack();
        this.player.pause();

      }
    });
    this.trackId = id;

  }

  openNav() {
    document.getElementById("mySidenav").style.width = "250px";
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  playerEnded() {
    this.onClickSongforPause(this.streamid);
    if ((this.songIndex + 1) < this.receiveddata.length) {
      const nextIndex = this.songIndex + 1;
      const nextTrackid = this.receiveddata[nextIndex].track_id;
      const nextSong = this.receiveddata[nextIndex].track_file;
      const nextPic = this.receiveddata[nextIndex].album_pic;
      setTimeout(() => {
        this.onClickSong(nextSong, nextTrackid , this.songIndex);
      }, 1000);
      }
  }
  onClickAlbumns(albumsId, name) {
    this.albumnName = name;
    this.albumnid = albumsId;
    const info = {
      id: albumsId
    };
    this.commonService.postApi(info, 'albums/getSongByAlbumnId').subscribe(res => {
      if (res.success) {
        this.receiveddata = res.data;
        $('.viewSongs').modal('show');
      }
    });
  }
  formMasterValidation() {
    this.applicationForm = this.formBuilder.group({
      track: ['', Validators.required],
      genreId: ['', Validators.required]
    });
  }
  get errorControl() {
    return this.applicationForm.controls;
  }
  onAddSongs() {

  }
  getFileName(data) {
    console.log('daata', data);
      this.songpath = data.filename;
      this.isFileUpload = true;
  }
  onFormSubmit() {
    if (this.applicationForm.invalid) {
      return;
    } else {
      // this.setValue();
      const info_to_send = {
        id: 0,
        trackName: this.errorControl.track.value,
        genreId: this.errorControl.genreId.value,
        albumId: this.albumnid,
        file: 'aaaaaaaaaaaa.mp3',
        enabled: true,
        userid: this.profileId
      };

      this.commonService.postApi(info_to_send, 'albums/add_update_track_1').subscribe(resp => {
        if (resp.success) {

        }
      });
  }
}
onClickLink(link) {
  window.location.replace(link);
}
}

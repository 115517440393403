import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommonService } from '../../core/services';
import { ActivatedRoute } from '@angular/router';

import { PlayerService } from 'src/app/shared/player.service';
import { environment } from '../../../environments/environment';
import * as moment from 'moment'; // install moment npm install moment --save
const imgPath = environment.imgPath;
import { Meta } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-play-song',
  templateUrl: './play-song.component.html',
  styleUrls: ['./play-song.component.scss', './style.css']
  
})
export class PlaySongComponent implements OnInit {

  receiveddata = [];
  imgpath: string;
  img: string;
  isSongLoad = false;
  streamid: number;
  @ViewChild('player')
  playerRef;
  player: any;
  rUrl: string;

  isPlayClick = false;
  isScrossTimeLimit = false;
  trackName: string;
  artistName: string;
  returnUrl: string;

  constructor(private router: Router,
    private commonService: CommonService,
    public route: ActivatedRoute,
    private playerSer: PlayerService,
    private meta: Meta
  ) {
    this.imgpath = imgPath;

    playerSer.pauseTrack$.subscribe(() => {
      this.pauseTrack();
    });

  }
  ngOnInit() {
    document.getElementById('player').style.visibility = 'hidden';
    document.getElementById('confirmPopup').style.visibility = 'visible';
    this.rUrl = this.router.url;
    this.player = this.playerRef.nativeElement;
    this.isPlayClick = false;
    this.route.queryParams.subscribe(params => {
    this.returnUrl = params['url'];
      if (this.returnUrl !== undefined) {
        document.getElementById('confirmPopup').style.visibility = 'visible';
        document.getElementById('player_div').style.visibility = 'hidden';
        document.getElementById('player_div').style.display = 'none';
        document.getElementById('confirmPopup').style.display = '';

      } else {
        document.getElementById('confirmPopup').style.visibility = 'hidden';
        document.getElementById('player_div').style.visibility = 'visible';
        document.getElementById('player_div').style.display = '';
        document.getElementById('confirmPopup').style.display = 'none';

      }
$("#progress-view").mousedown(function(e) {
  var player: HTMLMediaElement = <HTMLAudioElement> document.getElementById("player");

  var offset = $("#progress-view").offset();
  var relativeX = (e.pageX - offset.left);
  var relativeY = (e.pageY - offset.top);
  var percent = ((relativeX/$("#progress-view").width())*100)
  var width = percent.toString() +'%'
  $('.progress-line').css('width', width);
  $('#progress-pin').css('left', $('.progress-line').width().toString() +'px');
  var totalTime = parseFloat($('.total-time').text());
  player.currentTime =  ((totalTime*percent)/100)*60;
});
$("#player").on("canplaythrough", function(e){
  var seconds = e.currentTarget.duration;
  var duration = moment.duration(seconds, "seconds");
  
  var time = "";
  var hours = duration.hours();
  if (hours > 0) { time = hours + ":" ; }
  
  time = time + duration.minutes() + ":" + duration.seconds();
  $(".total-time").text(time);
});
});

  this.route.params.subscribe(id => {
  // this.returnUrl = id.url;
      const info = {
        id: id.id
      };
      this.commonService.postApi(info, 'albums/getSongByTrackID').subscribe(result => {
        if (result.success) {

          this.receiveddata = result.data;
          this.img = this.receiveddata[0].album_pic;
          this.trackName = this.receiveddata[0].track_name;
          this.artistName = this.receiveddata[0].name;
          // Twitter
          this.meta.addTag({ name: 'twitter:card', content: 'player' });
          this.meta.addTag({ name: 'twitter:player', content: 'https://whoozlmusic.com' + this.rUrl });
          this.meta.addTag({ name: 'twitter:player:width', content: '504' });
          this.meta.addTag({ name: 'twitter:player:height', content: '504' });
          this.meta.addTag({ name: 'twitter:player', content: 'https://whoozlmusic.com' + this.rUrl });
          this.meta.addTag({ name: 'twitter:site', content: "@whoozlmusic" });
          this.meta.addTag({ name: 'twitter:title', content: this.receiveddata[0].track_name });
          this.meta.addTag({ name: 'twitter:description', content: this.receiveddata[0].album_name });
          this.meta.addTag({ name: 'twitter:image', content: this.imgpath + this.img });
          // Facebook
          this.meta.addTag({ property: 'og:url', content: 'https://whoozlmusic.com' });
          this.meta.addTag({ property: 'og:type', content: 'article' });
          this.meta.addTag({ property: 'og:title', content: 'Play Song' });
          this.meta.addTag({ property: 'og:image', content: this.imgpath + this.img });

          const aud = document.getElementById('player');
          const call = this;
          this.playTrack(imgPath + this.receiveddata[0].track_file, this.receiveddata[0].id);
          aud.addEventListener("timeupdate", function(event) {
            var currentTime = (event.currentTarget as HTMLMediaElement).currentTime;
            var duration = (event.currentTarget as HTMLMediaElement).duration;
          var currentPlayTime = moment.duration(currentTime, "seconds");
          var fullLen = moment.duration(duration, "seconds");
          if (currentTime >= 40){
            call.pauseTrack();
            $("#player").remove();
            $(".btn").attr("disabled", "disabled");
            $('#playerImg').attr("src", "assets/images/play.png");
            call.isScrossTimeLimit = true;
          }
           $('.current-time').text(currentPlayTime.minutes() + ":" + currentPlayTime.seconds());
           $('.total-time').text(fullLen.minutes() + ":" + fullLen.seconds());
           var width = ((currentTime +.25)/duration*100).toString() +'%'
           $('.progress-line').css('width', width);
           $('#progress-pin').css('left', $('.progress-line').width().toString() +'px');
        });
          aud.onpause = function () {
            call.pauseTrack();
          };
        }
      });

    });
  }
  playTrack(track, trackid) {
    const data = {
      trackId: trackid
    };
    this.player.src = track;
    this.commonService.postApi(data, 'albums/start_track_1').subscribe(result => {
      if (result.success == 1) {
    this.isSongLoad = true;
    this.streamid = result.data;
      }
    });
  }

  playerEnded() {
    this.playerSer.trackEnded();
    var nextID = parseInt(this.receiveddata[0].id) + 1;
    window.location.href = "https://whoozlmusic.com/play-song/" + nextID;
  }

  hideConfirmAlert(){
    document.getElementById('confirmPopup').style.visibility = 'hidden';
    document.getElementById('player_div').style.visibility = 'visible';
    document.getElementById('player_div').style.display = '';
    document.getElementById('confirmPopup').style.display = 'none';

  }
  playNext() {
    var nextID = parseInt(this.receiveddata[0].id) + 1;
    window.location.href = "https://whoozlmusic.com/play-song/" + nextID;
  }
  playerPrivious() {
    var nextID = parseInt(this.receiveddata[0].id) - 1;
    window.location.href = "https://whoozlmusic.com/play-song/" + nextID;
  }

  pauseTrack() {
    const info = {
      streamId: this.streamid
    };
    this.commonService.postApi(info, 'albums/stop_track_1').subscribe(result => {
      if (result.success == 1) {
        this.player.pause();
      }
    });
  }
  onPlayClick() {
    if (this.isScrossTimeLimit == true) {
          return
    }

    if (this.isPlayClick == false) {
      $('#playerImg').attr("src", "assets/images/pause.png");
      this.player.play();
      this.isPlayClick = true;
    } else {
      $('#playerImg').attr("src", "assets/images/play.png");
      this.player.pause();
      this.isPlayClick = false;
    }
    console.log("Play");
  }
  onClickOk() {
    let rurl: string;
    rurl = 'https://7xo7a.app.link/' + this.returnUrl;
    window.location.href = rurl;
  }
  onCancel() {
    $('#confirmPopup').modal('hide');
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { IFormMasterSector } from '../../../user-managment/interface';
import { CommonService } from '../../../core/services';
import { NotificationService } from '../../../user-managment/notification/notification';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
const imgPath= environment.imgPath;

@Component({
  selector: 'app-fan-form',
  templateUrl: './fan-form.component.html',
  styleUrls: ['./fan-form.component.scss']
})
export class FanFormComponent implements OnInit {
  applicationForm: FormGroup;
  formDetails: IFormMasterSector = {} as IFormMasterSector;
  artistId: number;
  albumPic: string;
  imgpath: string;
  countryArray= [];
  genreArray= [];
  isFileUpload = false;

  genderArray = [
    {value: 'Male', viewValue: 'Male'},
    {value: 'Female', viewValue: 'Female'},
    {value: 'I choose not to disclose', viewValue: 'I choose not to disclose'}
  ];
  constructor(
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    public notify: NotificationService,
    private router: Router,
    public route: ActivatedRoute,
  ) {
    this.imgpath = imgPath;
   }


  ngOnInit() {
    this.route.params.subscribe(id => {
      const country = {
        countryId: 0,
        showAll: true
      }
      this.commonService.postApi(country, 'master/get_country_1').subscribe(result => {
        if(result.success){
       
          this.countryArray = result.data;
        }
      })
      const genre = {
        genreId: 0,
        showAll: true
      }
      this.commonService.postApi(genre, 'master/get_genre_1').subscribe(result => {
        if(result.success){
       
          this.genreArray = result.data;
        }
      })
      if (id.id == '0') {
        this.artistId = +id.id;
      } else {
        this.artistId = +id.id;
        const info = {
          artistId: +id.id
        };
        this.commonService.postApi(info, 'users/getProfileById').subscribe(result => {
          if (result.success) {
            this.applicationForm.controls.firstName.setValue(result.data[0].first_name);
            this.applicationForm.controls.lastName.setValue(result.data[0].last_name);
            this.applicationForm.controls.gender.setValue(result.data[0].gender);
            this.applicationForm.controls.genreId.setValue(result.data[0].genre_id);
            this.applicationForm.controls.countryId.setValue(result.data[0].country_id);
            this.applicationForm.controls.profileIntro.setValue(result.data[0].profile_intro);
            this.applicationForm.controls.website.setValue(result.data[0].website);
            this.applicationForm.controls.store.setValue(result.data[0].store);
            this.applicationForm.controls.phoneNumber.setValue(result.data[0].phone_no);
            this.applicationForm.controls.address.setValue(result.data[0].address);
            this.applicationForm.controls.paypalEmail.setValue(result.data[0].paypal_email);
            this.applicationForm.controls.facebookLink.setValue(result.data[0].facebook_link);
            this.applicationForm.controls.instagramLink.setValue(result.data[0].instagram_link);
            this.applicationForm.controls.twitterLink.setValue(result.data[0].twitter_link);
            this.applicationForm.controls.isUserEnable.setValue(result.data[0].enabled);
            this.albumPic = result.data[0].profile_pic;
            
          }
        });
      }
    });

    this.formMasterValidation();
  }

  // *****form validation*****
  formMasterValidation() {
    this.applicationForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: [''],
      gender: ['', Validators.required],
      genreId: [''],
      countryId: ['', Validators.required],
      profileIntro: [''],
      website: [''],
      store: [''],
      phoneNumber: ['', Validators.required],
      address: ['', Validators.required],
      paypalEmail: [''],
      facebookLink: [''],
      instagramLink: [''],
      twitterLink: [''],
      enabled: [''],
      isUserEnable: [false] 
    });
  }

  // *****easy access to error controls*****
  get errorControl() {
    return this.applicationForm.controls;
  }
  // *****form submit*****
  onFormSubmit() {
    if (this.applicationForm.invalid) {
      return;
    } else {
      const info_to_send = {
        id: this.artistId,
        firstName: this.errorControl.firstName.value,
        lastName: this.errorControl.lastName.value,
        gender: this.errorControl.gender.value,
        file: this.albumPic,
        countryId: this.errorControl.countryId.value,
        profileIntro: this.errorControl.profileIntro.value,
        website: this.errorControl.website.value,
        store: this.errorControl.store.value,
        phoneNumber: this.errorControl.phoneNumber.value,
        address: this.errorControl.address.value,
        paypalEmail: this.errorControl.paypalEmail.value,
        facebookLink: this.errorControl.facebookLink.value,
        twitterLink: this.errorControl.twitterLink.value,
        instagramLink: this.errorControl.instagramLink.value,
        genreId: this.errorControl.genreId.value,
        isUserEnable: this.errorControl.isUserEnable.value,
        isfileuploaded: this.isFileUpload,
  
  
  
  
      }
      this.commonService.postApi(info_to_send, 'users/edit_profile_1').subscribe(result => {
        
        if (result.success) {
          this.notify.success(result.msg);
          this.router.navigate(['/admin/fan-view']);
        }
      });
    }
  }
  getFileName(data) {
    if (data.parent !== undefined) {
      this.albumPic = data.filename;
      this.isFileUpload = true;
    }
  }
}

export const NumberPattern = '^[0-9]*';

export const StringPattern = '^[a-zA-Z ]*';

export const  PasswordPattern = '^(?=.*[0-9A-Z])(?=.*[a-zA-Z])(?=.*[@$!%*#?&])([a-zA-Z0-9!@#$%^&*]{8,12})$';

export const AddressPattern = '^[ A-Za-z0-9.,#-/()]*$';

export const  Email = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';

export const AlphaNumeric = '^[a-zA-Z0-9]*$';

export const panPattren = '^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$';

// tslint:disable-next-line:quotemark
export const fullNamePattern = "[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$";

export const CapacityRatingPattern = '^[0-9./]*$';

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError, Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map, filter } from 'rxjs/operators';
import { CryptoService } from '../../core/crypto/crypto.service';
import { Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

export class CommonService {

  baseUrl = environment.baseUrl;
  userName: string;

  constructor(
    private http: HttpClient,
    private crypto: CryptoService
  ) { }

  // *****check the error in thr from control*****
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const differnceInMaxLength = validatorValue.actualLength - validatorValue.requiredLength;
    const differnceInMinLength = validatorValue.requiredLength - validatorValue.actualLength;
    const maximumValueAllowed = validatorValue.max;
    if (validatorName === 'pattern') {
      const offset = validatorValue.requiredPattern;
      switch (offset) {
        case '^[a-zA-Z]*$':
          return 'Please enter valid characters (eg. a-z ,A-z) ';

        case '^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$':
          return 'Please enter valid characters (eg. CEQPK4956K) ';

        case '^[ A-Za-z0-9.,#-/()]*$':
          return 'Please enter valid characters (eg. a-z ,A-z ,0-9,.#-/()) ';

        case '^[0-9]*$':
          return 'Please enter integer only (eg. 0-9)';

        case '^[a-zA-Z0-9]*$':
          return 'Please enter valid characters (eg. a-z ,A-z ,0-9) ';

        case '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$':
          return 'Please enter the valid email (eg. jhon@gmail.com)';

        // tslint:disable-next-line:quotemark
        case "^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$":
          return 'Please enter the valid character (eg.jhon doe.com)';

        case '^(?=.*[0-9A-Z])(?=.*[a-zA-Z])(?=.*[@$!%*#?&])([a-zA-Z0-9!@#$%^&*]{8,12})$':
          return 'Minimum eight characters, at least one uppercase , one lowercase , one number and one special character';
      }
    }
    const config = {
      required: 'Please enter the value as it is required.',
      pattern: 'Invalid character entered.',
      minlength: `Please enter ${differnceInMinLength} more characters`,
      maxlength: `You have entered ${differnceInMaxLength} more characters than allowed.`,
      email: 'Invalid email address',
      invalidPassword: 'Invalid password. Password must be at least 6 characters long, and contain a number.',
      wrongLTForm: 'IF TOTAL LOAD ABOVE 100kW, PLEASE FILL HT FORM',
      wrongHTForm: 'IF TOTAL LOAD BELOW 100kW, PLEASE FILL LT FORM',
      max: `Invalid value. Cannot be more than ${maximumValueAllowed}`,
    };
    return config[validatorName];
  }

  // *****Post  service *****
  postApi(payload, url): Observable<any> {
    return this.http.post<any>(this.baseUrl + url, payload)
      .pipe(
        catchError(this.handleError)
      );
  }

  // *****remove the pdf*****
  deletePdf(payload): Observable<any> {
    const object = {
      filename: payload
    };
    return this.http.post<any>(this.baseUrl + 'pdf/delete', object)
      .pipe(
        catchError(this.handleError)
      );
  }

  // *****get the session decrypt pin*****
  getSession(key) {
    const pin = sessionStorage.getItem(key);
    return this.crypto.get('', pin);
  }

  // *****set the session encrypt*****
  setSession(key, pin) {
    const encrypt = this.crypto.set('', pin);
    sessionStorage.setItem(key, encrypt);
  }

  // *****get the localstorage decrypt pin*****
  getLocal(key) {
    const data = localStorage.getItem(key);
    return this.crypto.get('', data);
  }

  // *****set the localstorage encrypt*****
  setLocal(key, data) {
    const encrypt = this.crypto.set('', data);
    localStorage.setItem(key, encrypt);
  }

  // *****upload a server*****
  downloadPDF(data): Observable<any> {
    const header = new HttpHeaders();
    header.append('Content-Type', 'multipart/form-data');
    header.append('Accept', 'application/pdf');
    return this.http.post(this.baseUrl + 'pdf/serverUpload', data, {
      headers: header
    });
  }

  // *****download the pdf files *****
  download(data): Observable<any> {
    const body = { filename: data };
    const header = new HttpHeaders();
    header.append('Content-Type', 'application/json');
    header.append('Accept', 'application/pdf');
    return this.http.post(this.baseUrl + 'pdf/download', body, {
      responseType: 'blob',
      headers: header
    });
  }

  // *****validation*****
  validation(pattern, min, max) {
    const index = pattern;

    switch (index) {
      case 'Required':
        return ['', Validators.required];

      case 'Not Required':
        return ['', Validators.compose([Validators.minLength(min), Validators.maxLength(max)])];

      case 'Email':
        return ['', Validators.compose([Validators.required, Validators.email])];

      case 'null':
        return [null];

      default:

        // tslint:disable-next-line:max-line-length
        return ['', Validators.compose([Validators.required, Validators.pattern(pattern), Validators.minLength(min), Validators.maxLength(max)])];
    }

  }


  // *****set trhe date****

  setDate() {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), date.getUTCDate());
  }


  // *****check the error in the ajax call*****
  public handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('Client Side Error :', errorResponse.error.message);
    } else {
      console.error('Server Side Error :', errorResponse);
    }
    return throwError('There is a problem with the service. We are notified & working on it. Please try again later.');
  }

  setUsername(value) {
    this.userName = value;
    console.log('::::::this.username', this.userName);

  }
  getUsername() {
    return this.userName;
  }
}

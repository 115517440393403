import { Injectable } from '@angular/core';
import { SnotifyService } from 'ng-snotify';

@Injectable({
    providedIn: 'root'
  })

export class NotificationService {

    constructor(
        private snotifyService: SnotifyService
        ) { }

    warning(body: string) {
        console.log('notification warning', body);

        this.snotifyService.warning(body, 'Warning', {
            timeout: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            showProgressBar: true,
            position: 'rightBottom'
        });
    }


    error(body: string) {
        console.log('notification error', body);

        this.snotifyService.error(body, 'Error', {
            timeout: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            showProgressBar: true,
            position: 'rightBottom'
        });
    }

    success(body: string) {
        console.log('notification success', body);
        
        this.snotifyService.success(body , 'Success', {
            timeout: 1000,
            closeOnClick: true,
            pauseOnHover: true,
            showProgressBar: true,
            position: 'rightBottom'
        });
    }
    confirm(body: string) {
        console.log('notification comfirm', body);

        this.snotifyService.confirm(body , 'confirm', {
            closeOnClick: true,
            pauseOnHover: true,
            showProgressBar: true,
            position: 'centerCenter'
        });
    }

    prompt(body: string) {
        console.log('notification Promt', body);

        this.snotifyService.prompt(body , 'confirm', {
            closeOnClick: true,
            pauseOnHover: true,
            showProgressBar: true,
            position: 'centerCenter'
        });
    }
}

import { Component, Input, Output, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '../../../environments/environment';
import { NotificationService } from '../../user-managment/notification/notification';
const URL = environment.baseUrl + 'pdf/upload';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'custom-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})

export class FileUploadComponent implements OnInit {


  @Input() name: string;
  @Input() documentType?: string;
  @Input() parent?: string;
  @Input() size?: number;
  // @ViewChild('uploadEl', { static: false }) uploadElRef: ElementRef;
  @ViewChild('uploadEl', { static: false } as any) uploadElRef: ElementRef;
  // tslint:disable-next-line:no-output-native
  @Output() change = new EventEmitter<any>();


  view = false;
  buttonText: string;
  alertMessage: string;
  TemporaryFileArray = [];
  allowedMimeFileTypes = [];
  maxFileSize = 1 * 1024 * 1024;
  public mainFileUploader: FileUploader;
  allowedFileTypesHTML = ['image/jpeg', 'image/png', 'image/jpg'];

  constructor(
    private notify: NotificationService
  ) { }

  ngOnInit() {
    console.log('Images upload', this.size);
    if (this.size != null || '' || undefined) {
      this.maxFileSize = this.size * 1024 * 1024;
    }

    // *****set the type of the file*****
    this.setDocumentType();


    // *****hit the service and retrive the file name*****
    this.mainFileUploader = new FileUploader({
      url: URL,
      method: 'post',
      disableMultipart: false,
      itemAlias: 'photo',
      maxFileSize: this.maxFileSize,
      allowedMimeType: this.allowedMimeFileTypes
    });

    // *****errorin the file *****
    this.mainFileUploader.onWhenAddingFileFailed = (file) => {
      console.log(file, '*******file');
      if (this.maxFileSize < file.size) {
        this.notify.warning('You are uploading a File above the allowed size.');
      }
    };

    this.mainFileUploader.onAfterAddingFile = (file) => {
      console.log(file, '*******file2');
      file.withCredentials = false;
      this.uploadElRef.nativeElement.value = '';
    };

    // *****when the file is uploaded is successfully*****
    this.mainFileUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      console.log('*******file3');
      // this.notify.success('file upload successfully');
      if (status !== 422) {
        this.change.emit({
          filename: response,
          parent: this.parent,
          fileuploaded: true
        });
      } else {
        this.notify.warning('Please check the file ');
      }
    };
  }

  // *****set the data through filr type*****
  setDocumentType() {
    switch (this.documentType) {
      case 'image':
        this.allowedMimeFileTypes = ['image/png', 'image/jpg', 'image/jpeg'];
        this.allowedFileTypesHTML = ['image/jpeg', 'image/png', 'image/jpg'];
        this.alertMessage = 'in ".jpeg/.jpg/.png" format less than ' + (this.maxFileSize / (1024 * 1024)) + 'MB';
        break;
      case 'pdf':
        this.allowedMimeFileTypes = ['application/pdf'];
        this.allowedFileTypesHTML = ['application/pdf'];
        this.alertMessage = 'in \'.pdf\' format less than ' + (this.maxFileSize / (1024 * 1024)) + 'MB';
        break;
      case 'imgpdf':
        this.allowedMimeFileTypes = ['application/pdf', 'image/png', 'image/jpg', 'image/jpeg'];
        this.allowedFileTypesHTML = ['application/pdf', 'image/jpeg', 'image/png', 'image/jpg'];
        this.alertMessage = 'in \'.pdf/.jpeg/.jpg/.png\' format less than ' + (this.maxFileSize / (1024 * 1024)) + 'MB';
        break;
      case 'pdfdwg':
        this.allowedMimeFileTypes = ['', 'application/pdf', 'application/acad', 'image/vnd.dwg', 'image/x-dwg', 'application/octet-stream'];
        this.allowedFileTypesHTML = ['', 'application/pdf', 'application/acad', 'image/vnd.dwg', 'image/x-dwg', 'application/octet-stream'];
        break;
      case 'audio':
        this.allowedMimeFileTypes = ['audio/mp3'];
        this.allowedFileTypesHTML = ['audio/mp3'];
        break;
      default:
        this.allowedMimeFileTypes = [''];
        this.allowedFileTypesHTML = [''];
    }
  }

}

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { catchError, map, flatMap } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';


@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  baseUrl = environment.baseUrl + 'Users/';

  public result: any;
  private subject = new Subject<any>();
  public checkConnection: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) { }

  // get the user are login
  public get currentUserValue() {
    return this.result = JSON.parse(localStorage.getItem('currentUser'));
  }

  // login service
  login(payload): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'login', payload)
      .pipe(
        map(data => {
          if (data.success === true) {
            this.subject.next(true);
            const user = data.token;
            localStorage.setItem('currentUser', JSON.stringify(user));
            return data;
          }
          return data;
        }),
        catchError(this.commonService.handleError)
      );
  }

  // signUp service
  signUp(payload): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'user_register', payload)
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

  // show the header token base
  isLogin() {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    if (token) {
      return true;
    } else {
      return false;
    }
  }

  // logout
  logout() {
    this.setLogoutFlag();
    this.clearLocalStorage();
  }
  clearLocalStorage() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('user');
    localStorage.removeItem('Role');
    localStorage.removeItem('route');
  }
  setLogoutFlag() {
    return this.http.post<any>(this.baseUrl + 'logoutflag', 'payload')
    .pipe(
        catchError(this.commonService.handleError)
      );
  }

  logoutViaEmail(payload) {
    return this.http.post<any>(this.baseUrl + 'logoutViaEmail', payload)
    .pipe(
        catchError(this.commonService.handleError)
      );
  }

  // *****forget Password****
  forgetPassword(payload) {
    console.log(payload, '*******payload');
    return this.http.post<any>(this.baseUrl + 'forget', payload)
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

  // *****verification SMS and the Email and the set the password*****
  verification(payload): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'verification', payload)
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

  // *****get the name*****
  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  // *****set the name*****
  sendMessage(message: string) {
    this.subject.next(message);
  }
}

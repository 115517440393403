import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommonService } from '../../core/services';
import { environment } from '../../../environments/environment';
const imgPath = environment.imgPath;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  imgpath: string;
  data = [];
  spotlightData = [];

  constructor(
    private router: Router,
    private commonService: CommonService,
) {
  this.imgpath = imgPath;

}
  ngOnInit() {
    this.commonService.postApi('', 'users/getFeaturedArtist1').subscribe(res => {
      if (res.success) {
        console.log(': success', res);
        this.data = res.data;
      }
    });
    this.commonService.postApi('', 'users/getSpotlightArtist1').subscribe(res => {
      if (res.success) {
        console.log(': success', res);
        this.spotlightData = res.data;
      }
    });
  }
  onClickSportight(id) {
    console.log('::ssss', id);
    
    this.router.navigate(['/user-detail/' + id]) ;
  }
}
